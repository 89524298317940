define("discourse/plugins/discourse-category-experts/discourse/initializers/category-experts-post-decorator", ["exports", "@ember/runloop", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/lib/plugin-api", "discourse-common/lib/icon-library"], function (_exports, _runloop, _ajax, _ajaxError, _pluginApi, _iconLibrary) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function setPostCategoryExpertAttributes(post, appEvents) {
    let opts = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {
      approved: true
    };
    (0, _ajax.ajax)(`/category-experts/${opts.approved ? "approve" : "unapprove"}`, {
      type: "POST",
      data: {
        post_id: post.id
      }
    }).then(response => {
      post.setProperties({
        needs_category_expert_approval: !opts.approved,
        category_expert_approved_group: opts.approved ? response.group_name : false
      });
      post.topic.setProperties({
        needs_category_expert_post_approval: response.topic_needs_category_expert_approval,
        expert_post_group_names: response.topic_expert_post_group_names
      });
      appEvents.trigger("post-stream:refresh", {
        id: post.id
      });
    }).catch(_ajaxError.popupAjaxError);
  }
  function initializeWithApi(api) {
    const requiresApproval = api.container.lookup("service:site-settings").category_experts_posts_require_approval;
    if (requiresApproval) {
      const appEvents = api.container.lookup("service:app-events");
      api.includePostAttributes("needs_category_expert_approval", "category_expert_approved_group", "can_manage_category_expert_posts");
      api.addPostMenuButton("category-expert-post-approval", attrs => {
        if (!attrs.can_manage_category_expert_posts) {
          return;
        }
        if (attrs.needs_category_expert_approval && !attrs.category_expert_approved_group) {
          return {
            action: "approveCategoryExpertPost",
            icon: "thumbs-up",
            className: "approve-category-expert-post",
            title: "category_experts.approve",
            label: "category_experts.approve",
            position: "first"
          };
        } else if (attrs.category_expert_approved_group && !attrs.needs_category_expert_approval) {
          return {
            action: "unapproveCategoryExpertPost",
            icon: "thumbs-down",
            className: "unapprove-category-expert-post",
            title: "category_experts.unapprove",
            position: "second-last-hidden"
          };
        }
      });
      api.attachWidgetAction("post", "approveCategoryExpertPost", function () {
        setPostCategoryExpertAttributes(this.model, appEvents, {
          approved: true
        });
      });
      api.attachWidgetAction("post", "unapproveCategoryExpertPost", function () {
        setPostCategoryExpertAttributes(this.model, appEvents, {
          approved: false
        });
      });
    }
    api.decorateWidget("post:after", helper => {
      const post = helper.getModel();
      (0, _runloop.next)(() => {
        const article = document.querySelector(`article[data-post-id="${post.id}"]`);
        if (!article) {
          return;
        }
        if (post.category_expert_approved_group) {
          article.classList.add("category-expert-post");
          article.classList.add(`category-expert-${post.category_expert_approved_group}`);
        } else if (post.needs_category_expert_approval) {
          article.classList.remove("category-expert-post");
        }
      });
    });
    api.decorateWidget("poster-name:after", helper => {
      const post = helper.getModel();
      if (post && post.category_expert_approved_group) {
        return helper.h(`span.category-expert-indicator.category-expert-${post.category_expert_approved_group}`, (0, _iconLibrary.iconNode)("check-circle"));
      }
    });
  }
  var _default = _exports.default = {
    name: "discourse-experts-post-decorator",
    initialize() {
      (0, _pluginApi.withPluginApi)("0.10.1", api => {
        initializeWithApi(api);
      });
    }
  };
});