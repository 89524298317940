define("discourse/plugins/discourse-category-experts/discourse/components/modal/expert-group-chooser", ["exports", "@ember/component", "@glimmer/component", "@glimmer/tracking", "@ember/object", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _tracking, _object, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @closeModal={{@closeModal}}
    @title={{i18n "review.expert_group_chooser_modal.title"}}
    class="expert-group-chooser-modal"
    {{did-insert this.loadGroups}}
  >
    <TapTileGrid>
      {{#each this.groupOptions as |group|}}
        <TapTile @tileId={{group.id}} @onChange={{action "setGroupId"}}>
          {{group.name}}
        </TapTile>
      {{/each}}
    </TapTileGrid>
  </DModal>
  */
  {
    "id": "nLZdTiZ/",
    "block": "[[[8,[39,0],[[24,0,\"expert-group-chooser-modal\"],[4,[38,2],[[30,0,[\"loadGroups\"]]],null]],[[\"@closeModal\",\"@title\"],[[30,1],[28,[37,1],[\"review.expert_group_chooser_modal.title\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[8,[39,3],null,null,[[\"default\"],[[[[1,\"\\n\"],[42,[28,[37,5],[[28,[37,5],[[30,0,[\"groupOptions\"]]],null]],null],null,[[[1,\"      \"],[8,[39,6],null,[[\"@tileId\",\"@onChange\"],[[30,2,[\"id\"]],[28,[37,7],[[30,0],\"setGroupId\"],null]]],[[\"default\"],[[[[1,\"\\n        \"],[1,[30,2,[\"name\"]]],[1,\"\\n      \"]],[]]]]],[1,\"\\n\"]],[2]],null],[1,\"  \"]],[]]]]],[1,\"\\n\"]],[]]]]]],[\"@closeModal\",\"group\"],false,[\"d-modal\",\"i18n\",\"did-insert\",\"tap-tile-grid\",\"each\",\"-track-array\",\"tap-tile\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-category-experts/discourse/components/modal/expert-group-chooser.hbs",
    "isStrictMode": false
  });
  let ExpertGroupChooserModal = _exports.default = (_class = class ExpertGroupChooserModal extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "groupId", _descriptor, this);
      _initializerDefineProperty(this, "groupOptions", _descriptor2, this);
    }
    loadGroups() {
      const expertGroupIds = this.args.model.reviewable.category.custom_fields.category_expert_group_ids.split("|");
      (0, _ajax.ajax)("/groups.json").then(response => {
        this.groupOptions = response.groups.filter(group => expertGroupIds.includes(group.id.toString()));
      });
    }
    setGroupId(val) {
      this.args.model.reviewable.set("group_id", val);
      this.args.closeModal();
      this.args.model.performConfirmed(this.args.model.action);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "groupId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "groupOptions", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "loadGroups", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "loadGroups"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "setGroupId", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setGroupId"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, ExpertGroupChooserModal);
});